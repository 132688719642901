import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const noop = () => {};

export function makeGlobalSideEffectHandler<T>(
  handleStateChange: (values: T[]) => void | (() => void)
) {
  const Context = createContext<(val: T) => () => void>(() => noop);

  function Provider(props: { children: ReactNode }) {
    const [valueMap, setMap] = useState<{ [key: number]: T }>({});
    // Using a ref instead of React state for id because we don't need re-rendering
    // to happen and want to be able to change the value immediately.
    const idRef = useRef(0);

    const register = useCallback(
      (value: T) => {
        // Create an ID for this value and add it to the map
        const id = idRef.current;
        idRef.current += 1;
        setMap(obj => ({
          ...obj,
          [id]: value,
        }));
        return () => {
          // Remove the value from the map on unmount
          setMap(obj => {
            const { [id]: _, ...rest } = obj;
            return rest;
          });
        };
      },
      [idRef, setMap]
    );

    useEffect(() => {
      // Recompute the state and re-execute the effect whenever
      // the values change. The given function can return a cleanup
      // function if that's needed.
      return handleStateChange(Object.values(valueMap));
    }, [valueMap]);

    return (
      <Context.Provider value={register}>{props.children}</Context.Provider>
    );
  }

  function useGlobalEffect(param: T) {
    const register = useContext(Context);
    useEffect(() => {
      return register(param);
      // Effect will only run on first mount, unregistering on unmount
    }, []);
  }
  return { Provider, useGlobalEffect };
}
