import React, { useState, useCallback, ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet as Head } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';
// import Nav from 'components/Nav';
import theme from 'style/theme';
// import Blur from 'components/Blur';
import useWindowHeight from 'hooks/use_window_height';
import { Provider as BodyClassProvider } from '../hooks/use_body_class';

import 'style/base.css';

interface LayoutProps {
  children: ReactElement;
}

export default function Layout({ children }: LayoutProps) {
  const {
    site: { siteMetadata: meta },
    // menulinksYaml: { links },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          keywords
          description
        }
      }
    }
  `);
  // const [open, setOpen] = useState(false);
  // const toggle = useCallback(() => {
  //   setOpen(!open);
  // }, [open]);
  useWindowHeight();
  return (
    <ThemeProvider theme={theme}>
      <BodyClassProvider>
        <>
          <Head titleTemplate={`%s | ${meta.title}`} defaultTitle={meta.title}>
            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords.join(',')} />
            <html lang="en" />
            {/* sets up twitter to display every page as a card with default info */}
            <meta name="twitter:card" content="summary" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.description} />
          </Head>
          {children}
          {/* <Nav links={links} toggle={toggle} open={open} />
          <Blur blurred={open}>{children}</Blur> */}
        </>
      </BodyClassProvider>
    </ThemeProvider>
  );
}
